<template>
  <div class="son-page">
    <div class="one_box">
      <div class="right_box">
        <div class="member_view mb20">
          <div class="yuan_FF761D DW_nei_txt">商品总数</div>
          <div class="member_view_num">{{goodsdata.goods_count}}</div>
        </div>
        <div class="member_view mb20">
          <div class="yuan_75BAFF DW_nei_txt">销量最好</div>
          <div class="member_view_num">{{goodsdata.max_goods}}</div>
        </div>
        <div class="member_view mb20">
          <div class="yuan_95C717 DW_nei_txt">销量最差</div>
          <div class="member_view_num">{{goodsdata.min_goods}}</div>
        </div>
      </div>
    </div>
    <div class="one_box">
      <div class="left_box">
        <div class="left_box_txt">
          <div @click="numBtn" :class="storePrams.sort=='num'?'txt_FF761D shou':'shou'">商品销量排行</div>
          <div style="width:50px;text-align:center"><Icon type="md-swap" /></div>
          <div @click="quotaBtn" :class="storePrams.sort=='quota'?'txt_FF761D shou':'shou'">商品销量额排行</div>
        </div>
        <div style="margin:20px 0">
          <DatePicker @on-change="time" type="daterange" placement="bottom" placeholder="点击选择时间" style="width: 200px"></DatePicker>
          <Button @click="searchBtn" class="btn ml20">搜索</Button>
          <Button @click="daochu" class="ml20" style="background:#2DB7F5;color:#FFF" icon="ios-download-outline" type="info"></Button>
        </div>
        <div class="table_title">
          <div class="item_title">排序</div>
          <div class="item_title">商品名</div>
          <div class="item_title">销量</div>
          <div class="item_title">销售额</div>
        </div>
        <div>
          <div v-if="salasdata.length!=0">
            <div v-for="(item,index) of salasdata" :key="index" class="table_content">
              <div class="item_content">{{index+1}}</div>
              <div class="item_content">{{item.goods_name}}</div>
              <div class="item_content">{{item.num}}</div>
              <div class="item_content FF761D">{{item.quota}}</div>
            </div>
          </div>
          <div v-else style="line-height:50px;color:#999;text-align:center">暂无数据~</div>
          <br/>
          <Page :total="total"
            :current="storePrams.page"
            style="text-align:right"
            show-elevator
            show-total
            @on-change="pageChange"
            :page-size="storePrams.limit"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {goodshome,goodssalas} from '@/api/money'
import config from '@/config';
import {getCookies} from '@/utils/helper'
export default {
  name: "canteen",
  data(){
    return{
      uploads: config.apiUrl + '/storeapi/goodsExport',
      goodsdata:{},
      salasdata:[],
      total:0,
      storePrams:{
        start_time:'',
        end_time:'',
        sort:'num',
        page:1,
        limit:8
      }
    }
  },
  created() {
    this.getgoodshome()
    this.getgoodssalas()
  },
  methods:{
    getgoodshome(){
      goodshome().then(res=>{
        this.goodsdata = res.data
      })
    },
    getgoodssalas(){
      goodssalas(this.storePrams).then(res=>{
        this.salasdata = res.data.data
        this.total = res.data.total
      })
    },
    daochu(){
      location.href = this.uploads+'?token='+getCookies('canyin_token')+'&num='+this.storePrams.sort+'&start_time='+this.storePrams.start_time+'&end_time='+this.storePrams.end_time
    },
    numBtn(){
      this.storePrams.sort = 'num'
      this.getgoodssalas()
    },
    quotaBtn(){
      this.storePrams.sort = 'quota'
      this.getgoodssalas()
    },
    searchBtn(){
      this.getgoodssalas()
    },
    time(e){
      this.storePrams.start_time = e[0]
      this.storePrams.end_time = e[1]
    },
    pageChange(index){
      this.storePrams.page = index
      this.getgoodssalas()
    }
  }
}
</script>

<style scoped>
/* .son-page {

} */
.one_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.content_box{
  width: 100%;
  padding: 0 0 0 20px;
  border-radius: 12px;
  background: #FFF;
  margin: 30px 0;
}
.left_box_txt{
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
}
.txt{
  font-size: 20px;
  font-weight: 700;
  padding: 20px 10px;
}
.shou{
  cursor: pointer;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.txt_FF761D{
  color: #FF761D;
}
.ml20{
  margin-left: 20px;
}
.left_box{
  width: 100%;
  padding: 20px 30px;
  background: #FFF;
  border-radius: 12px;
}
.right_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
}
.mb20{
  margin-bottom: 45px;
}
.table_title{
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #F4F4F6;
  padding: 10px 0;
  height: 41px;
  border-radius: 20px;
}
.item_title{
  width: 20%;
  font-weight: 700;
  overflow: hidden;
  text-align: center;
}
.table_content{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  height: 50px;
  border-radius: 20px;
}
.FF761D{
  color: #FF761D!important;
}
.item_content{
  width: 20%;
  font-weight: 700;
  overflow: hidden;
  color: #999;
  text-align: center;
}
.member_view{
  width: 32%;
  padding: 20px 30px;
  background: #FFF;
  border-radius: 12px;
}
.yuan_FF761D::before{
  content: ' ';
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 100%;
  border: 2px solid #FF761D;
}
.yuan_75BAFF::before{
  content: ' ';
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 100%;
  border: 2px solid #75BAFF;
}
.yuan_95C717::before{
  content: ' ';
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 100%;
  border: 2px solid #95C717;
}
.DW_nei_txt{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}
.member_view_num{
  text-align: center;
  line-height: 50px;
  font-size: 28px;
  font-weight: 700;
}
</style>
